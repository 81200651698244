<template>
  <!--begin::Card-->
  <div class="row">
    <div class="col-12">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">
              Account Information
            </h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">Change your account settings</span>
          </div>
          <div class="card-toolbar">
            <button
              type="submit"
              @click="handleSubmitOperation"
              class="btn btn-success mr-2"
              ref="kt_save_changes"
            >
              <i v-show="loader" class="fa fa-spin fa-spinner"></i>
              Save Changes
            </button>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form" autocomplete="off">
          <div class="card-body">
            <!--begin::Heading-->
            <div class="row">
              <label class="col-xl-3"></label>
              <div class="col-lg-9 col-xl-6">
                <h5 class="font-weight-bold mb-6">Account:</h5>
              </div>
            </div>
            <!--begin::Form Group-->
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
              <div class="col-lg-9 col-xl-6">
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  v-model="formFields.name"
                />
                <p class="text-danger" v-show="formErrors.has('name')">
                  <span>{{ formErrors.first('name') }}</span>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Email Address</label>
              <div class="col-lg-9 col-xl-6">
                <div class="input-group input-group-lg input-group-solid">
                  <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-at"></i>
                                </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="Email"
                    v-model="formFields.email"
                  />
                </div>
                <p class="text-danger" v-show="formErrors.has('email')">
                  <span>{{ formErrors.first('email') }}</span>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Phone</label>
              <div class="col-lg-9 col-xl-6">
                <div class="input-group input-group-lg input-group-solid">
                  <div class="input-group-prepend">
                                <span class="input-group-text">
                                  <i class="la la-at"></i>
                                </span>
                  </div>
                  <input
                    type="text"
                    class="form-control form-control-lg form-control-solid"
                    placeholder="phone"
                    v-numericOnly.keyup
                    v-model="formFields.phone"
                  />
                </div>
                <p class="text-danger" v-show="formErrors.has('phone')">
                  <span>{{ formErrors.first('phone') }}</span>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-lg-3 col-form-label">Profile</label>
              <div class="col-lg-9 col-xl-6">
                <b-form-file
                  @change="onChangeFile"
                  id="profile">
                </b-form-file>
                <img
                  :src="getFile.download_url"
                  class="img-responsive mt-1"
                  height="100"
                  v-if="getFile && getFile.download_url" width="100" />
              </div>
            </div>
          </div>
        </form>
        <!--end::Form-->
      </div>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";
import JwtService from "@/core/services/jwt.service";
import { destroyToken } from "@/core/services/jwt.service";

const DEFAULT_DEFAULT_FORM_STATE = {
  id: null,
  name: null,
  email: null,
  phone: null,
  profile: {},
  _method: 'post',
};

export default {
  name: "AccountInformation",
  data() {
    return {
      loader: false,
      file: {},
      formFields: { ...DEFAULT_DEFAULT_FORM_STATE },
      formErrors: new Error({}),
    };
  },
  methods: {
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
        return data;

      } catch (error) {
      }
    },
    getDetail() {
      request({
        url: '/auth/user',
        method: 'get',
      })
      .then((response) => {
        const { data } = response.data;
        this.formFields = data;
        this.file = data.profile_pic;
      })
      .catch((error) => {
      });
    },
    async handleSubmitOperation() {
      this.loader = true;
      this.formErrors = new Error({});
      this.formFields.profile = this.getFile;
      try {
        const response = await request({
          url: 'users/profile',
          method: 'post',
          data: this.formFields,
        }).then((response) => {
          const { data } = response.data;

          const { access_token } = data;
          if ( access_token ) {
            JwtService.saveToken(JSON.stringify(data));
          }
          this.$global.itemUpdated();
          window.location.reload();
        });
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
      }
      this.loader = false;
    },
  },
  mounted() {
    this.getDetail();
  },
  computed: {
    getFile() {
      return this.file;
    }
  }
};
</script>
